import React from "react";
import { Link } from "gatsby";
import { getImageUrl } from "@takeshape/routing";

import routes from "../routes";

const ProjectListItem = ({ project, key }) => {
  const slugify = require("slugify");
  const projectSlug = slugify(project.name.toLowerCase());

  return (
    <article
      className="project-list--item f fdr x aic"
      id={projectSlug}
      key={key}
    >
      <div className="project--details">
        <div className="details--header">
          <a
            href={project.url}
            className="project--url"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h2>{project.name}</h2>
          </a>
          <h4>{project.headline}</h4>
        </div>
        <ul className="nostyle details--tasks">
          {project.projectTasks.includes("branding") && <li>+ Branding</li>}
          {project.projectTasks.includes("uxDesign") && <li>+ UX Design</li>}
          {project.projectTasks.includes("webDesign") && <li>+ Web Design</li>}
          {project.projectTasks.includes("development") && (
            <li>+ Development</li>
          )}
          {project.projectTasks.includes("ecommerce") && <li>+ E-Commerce</li>}
          {project.projectTasks.includes("shopify") && <li>+ Shopify</li>}
          {project.projectTasks.includes("shopify-plus") && <li>+ Shopify Plus</li>}
          {project.projectTasks.includes("klaviyo") && <li>+ Klaviyo</li>}
          {project.projectTasks.includes("strategy") && <li>+ Strategy</li>}
        </ul>
      </div>
      <div className="project--media">
        <a
          href={project.url}
          className="project--url"
          target="_blank"
          rel="noopener noreferrer"
        >
          {project.coverImage !== null ? (
            <img
              className="project--image"
              src={getImageUrl(project.coverImage.path, {
                h: 800,
                w: 800,
                fit: "contain"
              })}
              alt={project.name}
            />
          ) : (
            <img
              className="project--image"
              src="https://placekitten.com/600/400"
              alt={project.name}
            />
          )}
        </a>
      </div>
    </article>
    // <Link to={routes.project(project.name)} className="project"/>
  );
};

const ProjectList = ({ projects }) => (
  <div className="project-list f fdc">
    {projects.items.map((project, i) => (
      <ProjectListItem project={project} key={i} />
    ))}
  </div>
);

export default ProjectList;
